/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import 'theme/variables.scss';
@import 'theme/typography.scss';
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/dynamic-class.scss";
@import "./theme/bg-color.scss";
@import "./theme/table.scss";
@import "./app/_scrollbar/scrollbar.scss";
@import "./feather.css";

// $color-negative-rating: #0f0 !default;
// $color-ok-rating: #f00 !default;
// $color-positive-rating: #00f !default;
// $color-default-rating: #ff0 !default;
.comment-rating {
	.rating {
		justify-content: left !important;
	}
}

.rating.color-positive .star-container .star svg {
	fill: #ffc058 !important;
}

.star-container .star svg {
	color: #999 !important
}

.rating.color-negative .star-container .star svg {
	fill: #ffc058 !important;
}


@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&family=Roboto:wght@100..900&display=swap');

:root {
	--font-en: 'Roboto', sans-serif;
	--font-ar: 'Alexandria', sans-serif;
}

// Apply fonts dynamically based on language
body {
	font-family: var(--font-en);
}

// Arabic-specific styles
[lang="ar"] {
	font-family: var(--font-ar);
	direction: rtl;
	text-align: right;
}

[dir="rtl"] {
	* {
		font-family: var(--font-ar) !important;
	}

	text,
	ion-label,
	p,
	span,
	ion-title,
	div {
		font-family: var(--font-ar) !important;

	}
}

// for ios safe area issue
html {
	position: relative !important;
	top: env(safe-area-inset-top) !important;
	bottom: env(safe-area-inset-bottom) !important;
}

body {
	height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
}

ion-header ion-toolbar:first-of-type {
	padding-top: 0 !important;
	--background: white;
}


// for ios safe area issue

.password-icon-arabic {
	color: var(--ion-color-secondary-black);
	width: 1.375rem;
	position: absolute;
	left: 0.625rem;
	top: 1.25rem;
	font-size: 1.5rem;
	z-index: 9999;
	cursor: pointer;
}

.text-bold {
	font-weight: bold !important;
}

[dir="rtl"] {
	.text-right-rtl {
		text-align: right !important;
	}

	.text-left {
		text-align: right;
	}

	.text-right {
		text-align: left;
	}

	.arrow-custom-btn-rtl {
		right: unset !important;
		left: 0 !important;
		transform: rotate(180deg) !important;
		padding-top: 0 !important;
	}

	.in-english {
		display: none !important;
	}

	.in-arabic {
		display: block !important;
	}

	.modal-close {
		position: absolute;
		left: 0.9375rem;
		right: unset !important;
		top: 0.9375rem;
		cursor: pointer;
	}

	.product-fav {
		left: 0.625rem !important;
		right: auto !important;
	}
}

.title-block {
	display: flex;
	padding: 0 0.5rem;

	ion-title {
		text-align: center;
		position: relative;
		left: -4vw;
		font-size: 1.125rem;
		font-weight: 700;
		color: var(--ion-black-primary);
		text-transform: capitalize;
	}
}

.checked-price {
	text-decoration: line-through;
}

.desktop-visibility {
	display: block;
}

.mobile-visibility {
	display: none;
}

.desktop-visibility-in-flex {
	display: flex;
}

.mobile-visibility-in-flex {
	display: none;
}

.desktop-searchbar-ltr {
	.searchbar-search-icon.sc-ion-searchbar-md {
		right: -1px !important;
		left: auto !important;
		top: 0 !important;
		height: 46.5px !important;
		width: 46px !important;
		border-radius: 4px !important;

		@media screen and (max-width: 768px) {
			right: 30px !important
		}

	}

	.searchbar-input.sc-ion-searchbar-md {
		height: 46px;
		width: 100%;
		padding-inline-start: 1.375rem;
		padding-inline-end: 4.375rem;
		--placeholder-color: var(--ion-grey1);
		--box-shadow: none;
		border: 1px solid var(--ion-color-primary-contrast);
		background: var(--ion-color-primary-contrast);
		font-weight: normal;
		font-size: 0.875rem;

		@media screen and (max-width: 768px) {
			width: 80% !important;
			padding-inline-end: 1.375rem !important;
		}

	}

	.searchbar-clear-button.sc-ion-searchbar-md {
		right: 5.3rem;
	}
}

.desktop-searchbar-rtl {
	.searchbar-search-icon.sc-ion-searchbar-md {
		right: auto !important;
		left: 0 !important;
		top: 0 !important;
		height: 45.5px !important;
		width: 46px !important;
		border-radius: 4px !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		height: 46px;
		padding-inline-start: 1.375rem;
		padding-inline-end: 4.375rem;
		--placeholder-color: var(--ion-grey1);
		--box-shadow: none;
		border: 1px solid var(--ion-divider);
	}

	.searchbar-clear-button.sc-ion-searchbar-md {
		left: 3.125rem !important;

		@media screen and (max-width: 768px) {
			right: 4.125rem;
		}
	}
}

.mobile-searchbar {
	.searchbar-search-icon.sc-ion-searchbar-md {
		height: 1.125rem !important;
		width: 1.125rem !important;
		top: 0.8125rem !important;
	}

	.searchbar-input.sc-ion-searchbar-md {
		padding-inline-start: 2.625rem;
		--placeholder-color: var(--ion-grey1);
		--box-shadow: none;
		border: 1px solid var(--ion-divider);
		height: 42px;
	}
}

// add style on focus in searchbar
.searchbar-input.sc-ion-searchbar-md:focus {
	border-width: 2px;
}

// add pointer on search icon
.searchbar-search-icon.sc-ion-searchbar-md {
	cursor: pointer;
	pointer-events: unset;
}

.filter-accordion {
	.ion-accordion-toggle-icon {
		display: none !important;
	}

	.iconColor {
		color: var(--ion-app-primary) !important;
		pointer-events: none;
	}
}

.red-btn {
	background: var(--ion-app-primary);
	border-radius: 2px;
}

ion-header {
	--ion-statusbar-padding: 1.875rem;
}

.notify-me {
	text-align: left;
}

[dir="rtl"] .notify-me {
	text-align: right;
}

ion-content {
	--overflow: overlay;
}

.search-content {
	--offset-bottom: 0 !important;
	--overflow: auto !important;
	overflow: overlay;
}

.notification-ion-content,
.orders-ion-content {
	--overflow: auto !important;
	overflow: overlay;
}

.quantity-input {
	width: 50%;
	text-align: center;

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		appearance: none;
		-webkit-appearance: none;
		-moz-appearance: textfield;
		margin: 0;
	}
}

.review-title {
	ion-item {
		height: 3.75rem !important;
	}
}

.ion-page {
	justify-content: flex-start !important;
}

.common-text-area {
	ion-item {
		border: 1px solid var(--ion-divider);
		--padding-start: 0.875rem;
		--highlight-color-focused: transparent;
		--min-height: 56px;

		ion-textarea {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
			margin-top: 0;
			line-height: 1rem;
		}

		ion-label {
			padding-top: 0.125rem;

			.required-star {
				color: var(--ion-app-primary);
			}
		}

		.label-floating.sc-ion-label-md-h {
			color: var(--ion-grey1) !important;
			padding: 0;
			position: relative;
			bottom: 0.4375rem;
		}
	}
}

ion-spinner {
	--color: var(--ion-color-primary);
}

.common-input {
	ion-item {
		border: 1px solid var(--ion-divider);
		// --padding-start: 0.875rem;
		--highlight-color-focused: transparent;
		border-radius: 4px;
		max-height: 56px;
		min-height: 56px;
		align-items: center;
		display: flex;

		&:hover {
			border: 1px solid #404040;
		}

		ion-input {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
			// margin-top: 0.3125rem;
		}

		ion-textarea {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
		}

		ion-label {
			padding-top: 5px !important;


			.required-star {
				color: var(--ion-app-primary);
			}
		}

		.label-floating.sc-ion-label-md-h {
			color: var(--ion-grey1) !important;
			padding: 0;
			line-height: 0.4rem;
		}

		.native-input.sc-ion-input-md {
			padding-top: 0;
			padding-bottom: 0;
		}

		.countrycode-label {
			position: absolute;
			top: 1.75rem;
		}

		img {
			margin: 0.125rem 0.9375rem 0.125rem 0 !important;
		}
	}

	ion-item.mobile-phone {
		.native-input.sc-ion-input-md {
			padding-bottom: 0;
		}
	}

	.custom-ion-item {
		--padding-start: 0;
		--inner-padding-end: 0;
	}

	.mobile-phone {
		img {
			margin-right: 0.5625rem !important;
		}
	}
}

input[type="number"] {
	appearance: textfield !important;
	-moz-appearance: textfield !important;
}

.common-input-textarea {
	ion-item {
		border: 1px solid var(--ion-divider);
		--padding-start: 0.875rem;
		--highlight-color-focused: transparent;
		--min-height: 58px;
		height: 6.75rem;

		ion-input {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
			margin-top: 0.3125rem;
		}

		ion-textarea {
			--placeholder-color: var(--ion-grey1);
			--placeholder-font-weight: 400;
		}

		ion-label {
			padding-top: 0.125rem;

			.required-star {
				color: var(--ion-app-primary);
			}
		}

		.label-floating.sc-ion-label-md-h {
			color: var(--ion-grey1) !important;
			padding: 0;
			line-height: 1.4rem;
		}

		.native-input.sc-ion-input-md {
			padding-top: 0;
		}

		.countrycode-label {
			position: absolute;
			top: 1.6875rem;
		}

		img {
			margin: 0.125rem 0.9375rem 0.125rem 0 !important;
		}
	}

	.mobile-phone {
		img {
			margin-right: 0.5625rem !important;
		}
	}
}

[dir="rtl"] .common-input {
	ion-item {
		img {
			margin: 0.125rem 0 0.125rem 0.9375rem !important;
		}
	}
}

[dir="rtl"] .common-input-textarea {
	ion-item {
		img {
			margin: 0.125rem 0 0.125rem 0.9375rem !important;
		}
	}
}

.input-clear-icon {
	cursor: pointer;
	margin: 0 !important;
	width: 1.5rem;
}

.return-order-header,
.searchbar-container,
.return-order-footer,
.return-request-header,
.return-request-footer {
	display: none;
}

.search-div {
	align-self: center;

	ion-item {
		border: 1px solid var(--ion-color-primary-border);
		font-size: 0.75rem;
		padding: 0.1875rem 0;
		border-radius: 0;
		height: 38px;
		--min-height: 1.25rem;
		width: 271px;

		.sc-ion-input-md-h {
			--padding-top: 0.4375rem;
		}
	}
}

.section-block {
	display: flex;
	align-items: center;
}

.orders-listing,
.return-orders-listing,
.return-request,
.upper-part-watchlist {
	.listing-header {
		display: flex;
		justify-content: space-between;
		padding: 0.3125rem 0 1.5625rem 0;

		.title-div {
			font-size: 2rem;
			font-weight: bold;
			color: var(--ion-black-primary);
			text-transform: uppercase;

		}

		.search-and-filter {
			width: 100%;
			display: flex;
			justify-content: flex-end;

			.filter-div {
				display: flex;

				.filter-icon {
					display: flex;
					align-items: center;
					padding: 0 0.5625rem;
					cursor: pointer;
				}

				.close-icon {
					display: flex;
					align-items: center;
					padding: 0 0.3125rem;
					cursor: pointer;
					align-self: center;

					img {
						width: 1.5rem;
					}
				}

				.filter-label {
					cursor: pointer;
					font-weight: 600;
					font-size: 0.875rem;
					line-height: 1.0625rem;
					display: flex;
					align-items: center;
					letter-spacing: 0.3px;
					text-transform: uppercase;
					color: var(--ion-app-primary);
				}

				.close-label {
					color: var(--ion-black-primary) !important;
				}
			}
		}

		.search-and-filter {
			display: flex;
			justify-content: flex-end;

			.filter-div {
				display: flex;

				.filter-icon {
					display: flex;
					align-items: center;
					padding: 0 0.5625rem;
					cursor: pointer;
				}

				.close-icon {
					display: flex;
					align-items: center;
					padding: 0 0.3125rem;
					cursor: pointer;
					align-self: center;

					img {
						width: 1.5rem;
					}
				}
			}
		}
	}

	.listing-table {
		margin: 0 0 1.25rem;
		overflow: overlay;
		border-left: 1px solid var(--ion-divider);
		border-right: 1px solid var(--ion-divider);

		.table {
			width: 100%;

			.column-heading {
				font-weight: bold;
				font-size: 0.925rem;
				line-height: 1rem;
				text-transform: uppercase;
				color: var(--new-text-color);
				padding: 0.975rem 0.5625rem 0.975rem 0.5625rem;
			}

			.order-id-col {
				font-weight: bold !important;
				max-width: 200px;
				width: 200px;
				min-width: 200px;
			}

			.date-col {
				max-width: 170px;
				width: 160px;
			}

			.status-col {
				max-width: 165px;
				width: 165px;
				min-width: 165px;
			}

			.ship-to-col {
				min-width: 280px;
				max-width: 300px;
			}

			.order-total-col {
				max-width: 200px;
				width: 200px;
				min-width: 200px;
			}

			.download-col {
				max-width: 125px;
				width: 125px;
				min-width: 125px;
			}

			.arrow-col {
				min-width: 35px;
				max-width: 40px;
			}

			.table-body {
				font-size: 1rem;
				letter-spacing: 0;
				color: var(--ion-black-primary);
				opacity: 1;

				.table-row {
					border-bottom: 1px solid var(--ion-divider);
					cursor: pointer;

					td {
						font-weight: 500;
						font-size: 0.875rem;
						line-height: 1rem;
						padding: 0.4375rem 0.5625rem 0.4375rem 0.5625rem;
						line-height: 1.1875rem;
						color: var(--ion-black-primary);
						min-width: 120px;
						height: 40px;
					}

					.right-arrow {
						display: flex;
						align-items: center;
					}
				}
			}
		}
	}

	.mobile-view-listing {
		.order-container {
			.order-card {
				background: #ffffff;
				border: 1px solid var(--ion-divider);
				border-radius: 2px;
				margin: 0 0 0.625rem;

				.order-card-col {
					padding-top: 0.9375rem;
				}

				.grid-label {
					font-weight: 500;
					font-size: 0.6875rem;
					line-height: 0.8125rem;
					text-transform: uppercase;
					color: var(--ion-grey1);
				}

				.grid-values {
					padding-top: 0.1875rem;
					font-weight: 500;
					font-size: 1rem;
					line-height: 1.1875rem;
					color: #103e53;
				}
			}

			.mobile-right-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

.divider-line {
	height: 1px;
	width: 100%;
	background: var(--ion-divider);
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.status-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	column-gap: 40px;
}

.mobile-listing-grid {
	overflow: overlay;
	padding: 0 1.25rem;
	min-height: 587px;
}

.header-ios ion-toolbar:last-of-type {
	--border-width: 0 0 0 0 !important;
}

.return-orders-listing {
	overflow: overlay;
	overflow-x: hidden;
}

// Date popover ------------------------->
#datePopover {
	&::part(content) {
		top: 29vh;
		margin: 0 auto;
		left: 0 !important;
		right: 0 !important;
		transform-origin: left top;
		min-width: fit-content;
		max-width: fit-content;
		display: contents;

	}
}

.from-date-div,
.to-date-div {
	display: flex;
	align-items: center;
	width: 100%;
}

.from-date-label.RTL,
.to-date-label.RTL {
	margin-left: 0.625rem;
}

.from-date-label.LTR,
.to-date-label.LTR {
	margin-right: 0.625rem;
}

.from-date-label,
.to-date-label {
	text-transform: capitalize;

}

.from-date-class,
.to-date-class {
	background: var(--ion-color-primary-white) 0% 0% no-repeat padding-box;
	border: 1px solid var(--ion-divider);
	border-radius: 4px;
	opacity: 1;
	height: 50px;
	width: 100%;
	--min-height: 51px;
	display: flex;
	align-items: center;

	ion-icon {
		width: 1.125rem;
		margin: auto;
	}

	ion-input {
		--padding-bottom: 0;
		--padding-top: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: 40px;
		display: contents;
	}
}

.close-filter-icon {
	width: 1.75rem;
	height: 1.75rem;
	margin-right: 0.4375rem;
	cursor: pointer;

	img {
		vertical-align: bottom;
	}
}

.filter-buttons {
	display: flex;
	justify-content: flex-end;
	column-gap: 1rem;
	row-gap: 0.625rem;
}

.filter-apply-button {
	background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
	opacity: 1;
	font-size: 0.875rem;
	font-weight: bold;
	letter-spacing: 0;
	color: var(--ion-color-primary-contrast);
	text-transform: uppercase;
	width: 92px;
	height: 50px;

	&:disabled {
		opacity: 0.6;
		cursor: not-allowed;
	}
}

.listing-header-btn,
.listing-footer-btn {
	height: 46px;
	background: var(--ion-color-primary) 0% 0% no-repeat padding-box;
	opacity: 1;
	font-size: 0.95rem;
	padding: 0.8125rem 2rem;
	font-weight: bold;
	letter-spacing: 0;
	color: var(--ion-color-primary-contrast);
	text-transform: uppercase;
	opacity: 1;
}

.listing-footer-btn {
	width: 100%;
}

.return-order-listing-footer {
	padding: 0.625rem 1.25rem 1.875rem;
	width: 100%;
	box-shadow: 2px 0 6px #00000029;
}

.custom-select-dd {
	&::part(content) {
		border-radius: 0;
		max-height: 300px;
	}

	.item-radio-checked {
		font-size: 0.765rem;
	}

	ion-list {
		padding: 0;
	}

	ion-item.sc-ion-select-popover-md {
		text-transform: capitalize;
		font-size: 0.765rem;
	}
}

.per-page-dd {
	--offset-x: 30px !important;
	--offset-y: 50px !important;
}

.status-dropdown {
	background: var(--ion-color-primary-contrast) 0% 0% no-repeat padding-box;
	opacity: 1;
	height: 50px;
	display: flex;
	--ripple-color: transparent;
	--background-activated: transparent;
	padding: 0;

	ion-select {
		--min-height: 50px;
		min-height: 50px;
	}

	ion-item {
		--background-hover: transparent;
		--ripple-color: transparent;
		width: 100%;
		--min-height: 50px;

		ion-select {
			width: 100%;
			--padding-top: 0;
			--padding-bottom: 0;
			--background-hover: transparent;
			--ripple-color: transparent;
			--min-height: 50px;
		}
	}

	.item-interactive.item-has-focus {
		border: none !important;
	}

	ion-input {
		--padding-bottom: 0;
		--padding-top: 0;
	}
}

.status-btn,
.pending,
.shipped,
.canceled {
	min-width: 110px;
	width: auto;
	font-weight: 500;
	font-size: 0.875rem;
	height: 2.125rem;
	border-radius: 4px;
	text-transform: none;
	letter-spacing: 0;
	--padding-start: 0.4375rem;
	--padding-end: 0.4375rem;
}

.order-status-btn {
	min-width: 140px;
	font-weight: 500;
	font-size: 0.75rem !important;
	height: 2.125rem;
	border-radius: 4px;
	--border-radius: 4px !important;
	text-transform: none;
	letter-spacing: 0;
	--padding-start: 0.4375rem;
	--padding-end: 0.4375rem;

	@media screen and (max-width: 768px) {
		font-size: 0.9625rem !important;
	}

	ion-button {
		border-radius: 4px !important;
		--border-radius: 4px !important;
	}

}

.pending,
.under-review {
	background: rgba(109, 207, 246, 0.2);
	color: var(--ion-app-blue);
}

.shipped,
.returned {
	background: rgba(172, 219, 97, 0.25);
	color: var(--ion-app-green);
}

.canceled {
	background: rgba(233, 75, 75, 0.1);
	color: var(--ion-app-primary);
}

.shipping,
.partially-shipped {
	background: rgba(255, 121, 23, 0.17);
	color: var(--ion-app-orange);
}

.reject {
	background: var(--ion-btn-reject);
	color: var(--ion-app-orange);
}

.order-card {
	.status-btn {
		width: auto;
		font-weight: 500;
		font-size: 0.875rem;
		line-height: 0.875rem;
		text-transform: none;
		letter-spacing: 0;
	}
}

.justify-end {
	justify-content: flex-end !important;
}

.justify-center {
	justify-content: center;
}

.justify-space-beetwen {
	justify-content: space-between;
}


.item-padding-0 {
	--inner-padding-end: 0;
	--padding-start: 0;
}

.justify-start {
	justify-content: flex-start !important;
}

.justify-center {
	justify-content: center !important;
}

.input-div-container {
	position: relative;

	.status-label {
		position: absolute;
		left: 0;
		top: -28px;
	}
}

.search-div {
	margin-right: 1.25rem;
	align-self: center;
	min-width: 250px;
	width: 440px;

	@media screen and (max-width: 768px) {
		background: lightgray;
		padding: 0 10px;
	}

	input {
		border: 1px solid var(--ion-black-primary);
		font-size: 0.75rem;
		padding: 0.1875rem 0.625rem;
		border-radius: 0;

	}

	ion-input {
		--highlight-color-valid: var(--ion-divider) !important;
		--highlight-color: var(--ion-divider) !important;
		min-height: 50px !important;
		--highlight-height: 1px !important;

		img {
			width: 20px !important;
			height: 20px !important;
			min-width: 20px !important;
		}
	}
}

.search-icon {
	align-self: center;
	margin-top: 0.25rem;
	cursor: pointer;
}

.printer-icon {
	align-self: baseline;
	cursor: pointer;
}

.pagination-wrapper {
	max-width: 1800px;
	text-align: center;
}

.pagination-div {
	align-items: center;
}

.pagination-grid {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin: 1.25rem 0;

	&-tile {
		width: 33%;
		padding: 0;
	}
}

.perpage-dropdown {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 0;

	ion-item {
		font-size: 0.875rem;
		font-weight: 600;
		letter-spacing: 0;
		color: var(--ion-black-primary);
		border: 1px solid var(--ion-divider) !important;
		border-radius: 2px;
		opacity: 1;
		--padding-start: 0;
		--inner-padding-end: 0;
	}

	ion-select {
		width: 135px;
		padding: 0 10px;
	}
}

.perpage-title {
	font-size: 0.875rem;
	letter-spacing: 0;
	color: var(--ion-black-primary);
	opacity: 1;
	padding: 0 10px;
}

ion-select::part(icon) {
	opacity: 1;
	margin-left: 1rem;
	color: transparent;
	background: url("./assets/icon/down-arrow-dark.svg") no-repeat center;
}

.pagination-col {
	text-align: end;
}

.order-id {
	font-weight: bold;
	font-size: 1rem;
	letter-spacing: 0 !important;
	color: var(--ion-black-primary) !important;
	opacity: 1 !important;
}

.download-arrow {
	display: flex;
	align-items: center;
}

#addressModal {
	app-address {
		height: auto !important;

		@media screen and (max-width: 768px) {
			height: 100% !important;
		}
	}
}

#termsWebModal {
	height: auto !important;

	@media screen and (max-width: 768px) {
		height: 100% !important;
	}
}

#offerDialog {
	app-offer-dialog {
		height: auto !important;

		@media screen and (max-width: 768px) {
			height: 100% !important;
		}
	}
}

#returnOrderModal {
	.ion-page {
		justify-content: unset !important;
	}
}

// custom grid
.custom-grid {
	padding-left: 0;
	padding-right: 0;

	ion-row {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-gap: 4.6875rem 40px;

		ion-col {
			width: 100% !important;
			max-width: 100% !important;
			padding-left: 0;
			padding-right: 0;
			padding-inline-start: 0;
			padding-inline-end: 0;
		}
	}
}

.register-Modal,
.select-variant-modal {
	--height: 1119px;
	--border-radius: 0;
}

.cart-Modal {
	--height: 100%;
	--width: 400px;
	--min-height: 190px;
	--max-height: 450px;

	ion-content {
		--overflow: hidden;
		overflow: hidden;
	}

	// ion-modal .ion-page:not(ion-nav .ion-page),
	// .ion-page {
	//     position: absolute !important;
	// }
}

.cart-Modal::part(content) {
	top: 4.6875rem !important;
	position: absolute !important;
	right: 4.375rem !important;
	left: unset !important;
	box-shadow: 0 1.25rem 7.5rem #00000026;
	z-index: 99;
}

// sample modal
.sample-modal {
	.ion-page {
		padding: 2.125rem 3rem;
		font-size: 1rem;
		color: var(--ion-black-primary);

		.modal-close {
			position: absolute;
			top: 0.375rem;
			right: 0.375rem;
			cursor: pointer;
		}

		.upper-part {
			text-align: left;
			margin-bottom: 2.1875rem;

			.heading {
				font-weight: 600;
				font-size: 1.75rem;
				padding-bottom: 0.25rem;
			}

			.text {
				color: var(--ion-grey1);
				font-size: 1rem;
			}
		}

		.detail-section {
			border-bottom: 1px solid #ddd;
			padding-bottom: 2.1875rem;
			margin-bottom: 1.0625rem;
			text-align: left;

			.image-block-part {
				display: flex;
				justify-content: space-between;

				.left-part {
					display: flex;

					.product-image {
						width: 100px;
						height: 100px;
						object-fit: fill;
					}
				}

				.details {
					display: flex;
					flex-direction: column;

					.product {
						font-size: 1.12rem;
						font-weight: bold;
						margin-bottom: 0.75rem;
					}

					.options-block {
						font-size: 0.75em;
						margin-bottom: 0.375rem;

						.name {
							color: var(--ion-grey1);
						}
					}
				}

				.price {
					font-size: 1.12rem;
					font-weight: bold;
					margin-left: 0.1875rem;
				}
			}

			.note {
				color: var(--ion-grey1);
				font-size: 0.75em;
				margin-top: 1.5625rem;
				display: inline-block;
			}
		}

		.bottom-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;

			.cancel {
				cursor: pointer;
			}

			ion-button {
				--background: var(--ion-app-primary);
				width: 164px;
				height: 46px;
			}
		}
	}
}

#sampleModal {
	--height: 450px;
	--width: 600px;
}

.location-popover {
	ion-select-popover {
		--offset-bottom: auto !important;
		--overflow: hidden;
		overflow: overlay;
		max-height: 200px;

		&::-webkit-scrollbar {
			width: 0.625rem;
		}

		&::-webkit-scrollbar:horizontal {
			height: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--ion-divider);
			border-radius: 0.625rem;
		}

		&::-webkit-scrollbar-track {
			background-color: #ffffff;
			border-radius: 0.625rem;
		}
	}
}

// sample modal for mobile
.sample-modal-mobile {
	.ion-page {
		font-size: 1rem;
		color: var(--ion-black-primary);

		.modal-close {
			position: absolute;
			top: 1rem;
			right: 0.625rem;
			cursor: pointer;

			img {
				width: 2rem;
			}
		}

		.horizontal-line {
			border-bottom: 1px solid var(--ion-divider);
			margin: 1.25rem 0;
		}

		.upper-part {
			text-align: left;

			.heading {
				font-weight: bold;
				font-size: 1.12rem;
				text-align: center;
				color: var(--ion-black-primary);
			}

			.text {
				color: var(--ion-grey1);
				font-size: 1rem;
			}
		}

		.detail-section {
			margin: 0 1.25rem 1.875rem 1.25rem;
			text-align: left;

			.note-div {
				display: flex;
				margin-top: 1.5625rem;
				align-items: center;
				justify-content: space-between;

				.price {
					margin-left: 0.625rem;
					font-weight: bold;
					font-size: 1.12rem;
				}
			}

			.image-block-part {
				display: flex;
				justify-content: space-between;

				.left-part {
					display: flex;
					position: relative;

					.sample-tag {
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						width: 70px;
						height: 19px;
						font-size: 0.625rem;
						font-weight: bold;
						color: var(--ion-grey1);
						background: var(--ion-app-yellow);
					}

					.product-image {
						width: 100px;
						height: 100px;
						object-fit: fill;
					}
				}

				.details {
					display: flex;
					flex-direction: column;
					text-align: left;

					.product {
						font-size: 1.12rem;
						font-weight: bold;
						margin-bottom: 0.75rem;
					}

					.options-block {
						font-size: 0.75em;
						margin-bottom: 0.22rem;

						.name {
							color: var(--ion-grey1);
						}

						.value {
							color: var(--ion-gray3);
							font-weight: 500;
						}
					}

					.estimated-opt {
						background: var(--ion-divider);
					}
				}
			}

			.note {
				color: var(--ion-grey1);
				font-size: 0.75em;
				display: inline-block;
			}
		}

		.bottom-container {
			.cancel {
				cursor: pointer;
			}

			ion-button {
				--background: var(--ion-app-primary);
				width: 98%;
				height: 48px;
				font-size: 0.95rem;
				font-weight: bold;

				.cart-icon {
					margin: 0.625rem;
				}
			}
		}
	}

	// &::part(content) {
	//     --height: 400px;
	//     position: absolute;
	//     bottom: 0;
	// }
}

ion-button {
	--border-radius: 0;
	--box-shadow: none !important;
	--ripple-color: transparent !important;
	--background-activated: transparent !important;
	--background-focused: transparent !important;
}

[dir="rtl"] .cart-Modal::part(content) {
	direction: rtl;
	top: 4.6875rem !important;
	position: absolute !important;
	left: 5.625rem !important;
	right: unset !important;
	box-shadow: 0 1.25rem 7.5rem #00000026;
}

.company-registration {
	ion-label {
		margin-bottom: 0 !important;
	}

	ion-select {
		--placeholder-color: var(--ion-grey1) !important;
		--placeholder-opacity: 1 !important;
	}
}

#twoFaModal {
	--height: auto;
	--width: 560px;
	align-items: baseline;
	padding-top: 5.3125rem;
}

#sortByModal {
	--height: 335px;
	--width: 560px;
	align-items: flex-end;
}

#filterByModal {
	--height: 324px;
	align-items: flex-end;
}

#orderFilterByModal {
	--height: 360px;
	align-items: flex-end;
}

#mobileStatementFilterByModal {
	--height: 450px;
	align-items: flex-end;
}

#designAndColor {
	--height: auto;
	--max-height: 622px;
	align-items: flex-end;
}

#addEditAddressModal {
	--height: 480px;
	--width: 600px;
	align-items: baseline;
	padding-top: 5.3125rem;
}

#forgotPassword,
#twoFaModal_Account,
#changePassword {
	--height: auto;
	--width: 600px;
	align-items: baseline;
	padding-top: 5.3125rem;
}

@media screen and (max-width: 768px) {
	.custom-textarea {
		min-height: 119px;

		textarea {
			overflow: auto !important;
		}
	}

	ion-content {
		--offset-bottom: 0px !important;
		--overflow: auto !important;
		// overflow: auto !important;

		&::-webkit-scrollbar {
			width: unset !important;
		}

		&::-webkit-scrollbar:horizontal {
			height: unset !important;
		}

		&::-webkit-scrollbar-thumb {
			background-color: unset !important;
			border-radius: unset !important;
		}

		&::-webkit-scrollbar-track {
			background-color: unset !important;
			border-radius: unset !important;
		}
	}

	#changePassword,
	#addReviewModal,
	#notifyModal {
		--height: auto;
		align-items: flex-end;
		padding-top: 0;
	}

	.pagination-wrapper {
		display: none !important;
	}

	.listing-header {
		display: none !important;
	}

	.return-order-header,
	.searchbar-container {
		display: block;
	}

	.return-order-footer,
	.return-request-footer {
		display: flex;
	}

	.countrycode-label {
		position: absolute !important;
		top: 1.45rem !important;
		bottom: unset !important;
		left: 3rem !important;
	}

	.countrycode-label-edit {
		position: absolute !important;
		top: 0.8rem !important;
		bottom: unset !important;
		left: 2.6rem !important;
	}
}

#forgotPassword {
	--max-width: 560px;
}

.changePassword,
.forgotPassword,
.addEditAddressModal,
.offerDialog {
	--backdrop-opacity: 0.6 !important;
	background-color: black;
	--width: auto !important;
	--height: 296px !important;
	--border-radius: 0.9375rem !important;
}

#selectVariantModal {
	--backdrop-opacity: 0.6 !important;
	--width: 60% !important;
	--height: 100%;
	--max-height: 585px;
}

.twoFaModal {
	--backdrop-opacity: 0.6 !important;
}

.cancel-product-mobile-popup {
	--backdrop-opacity: 0.6 !important;
	align-items: flex-end;
	--height: fit-content !important;
}

#twoFaModal_Account::part(backdrop),
#loginModal::part(backdrop),
#addressModal::part(backdrop),
#offerDialog::part(backdrop),
#changePassword::part(backdrop),
#addEditAddressModal::part(backdrop),
#addEditPaymentCardModal::part(backdrop),
#forgotPassword::part(backdrop),
#registerModal::part(backdrop),
#selectVariantModal::part(backdrop),
#termsWebModal::part(backdrop) {
	--backdrop-opacity: 0.6 !important;
	background-color: black;
	--border-radius: 0;
}

#cartModal::part(backdrop) {
	--backdrop-opacity: 1 !important;
	background-color: transparent;
}

.twoFaModal .ion-page,
.changePassword .ion-page,
.forgotPassword .ion-page,
.addEditAddressModal .ion-page,
.addEditPaymentCardModal .ion-page {
	justify-content: normal !important;
}

#addEditAddressContent::part(scroll) {
	overflow: overlay !important;
	--overflow: overlay !important;
}


.custom-address-modal-height {
	--height: 500px;
	/* Adjust height as needed */
	--width: 600px;
	/* Adjust width if necessary */
	/* Ensures modal does not exceed the viewport */
	overflow-y: auto;

	@media (min-height:600px) and (max-height:2086px) {
		--height: 705px;
		--width: 600px;
	}

	/* Enables scrolling if content exceeds height */
}

.custom-address-modal-height .modal-wrapper {
	height: var(--height);
	width: var(--width);
	border-radius: 10px;
	/* Optional: Adjust border-radius */
}

.filterby-select::part(icon) {
	display: none;
}

.filterby-select::part(text) {
	display: none;
}

.mobile-view-listing {
	display: none;
}

.mobile-user-block-ltr {
	display: none;
}

.mobile-user-block-rtl {
	display: none;
}

.rotate-arrow {
	transform: rotate(180deg);
}

ion-title {
	text-transform: capitalize;

}

//header style for mobile
.custom-header {
	ion-title {
		font-size: 28px;
		text-transform: capitalize;

	}

	&::after {
		background-image: none !important;
	}
}

// content side padding
.content-side-padding {
	padding-left: 4.4375rem;
	padding-right: 4.4375rem;
}

// my wishlist style
.my-wishlist-page {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 63px 20px;
}

.upper-part-watchlist {
	display: flex;
	font-size: 1rem;
	flex-direction: column;

	.heading {
		font-weight: 700;
		font-size: 2.6rem;
	}

	.select-btn-block {
		display: flex;
		padding-top: 1.5625rem;
		padding-bottom: 2.875rem;
		justify-content: space-between;


		.select-block {
			display: flex;
			align-items: center;
			width: 100%;

			span {
				font-size: 0.87em;
				font-weight: 600;
				color: var(--ion-black-primary);
			}

			ion-select {
				border: 1px solid var(--grey-btn-bg);
				padding: 0.5625rem 0.3125rem;
				width: 100%;
			}
		}

		.add-cart-button {
			ion-button {
				--background: var(--ion-app-primary);
				--box-shadow: none;
				width: 225px;
				height: 3.375rem;
				--border-radius: 0;
				font-weight: 700;
				font-size: 1rem;
				line-height: 1.375rem;
				text-transform: uppercase;
				position: relative;
				margin-right: 0;

				.cart-icon {
					position: absolute;
					left: 0;
				}
			}
		}
	}
}

// top-slider
.top-slider {
	.slides {
		margin-top: 0 !important;
		margin-bottom: 0 !important;

		ion-slide {
			.slide-content {
				.image-section {
					img {
						display: none !important;
					}
				}
			}
		}
	}

	.swiper-container-horizontal>.swiper-pagination-bullets {
		left: 0;
		height: 1.25rem;
		top: 0;
		display: flex;

		.swiper-pagination-bullet {
			width: 100%;
			height: 0.375rem;
			display: inline-block;
			background: none;
			opacity: 0 !important;
			position: relative;
			margin: 0 0.5rem 0 0 !important;
			border-radius: 0 !important;
		}

		.swiper-pagination-bullet-active {
			opacity: 1 !important;
			background: var(--ion-app-primary) !important;
		}
	}
}

.middle-banner {
	.swiper-pagination-bullet {
		width: 45px;
		height: 4px;
		display: inline-block;
		background: none;
		opacity: 0.4 !important;
		background: var(--ion-color-medium) !important;
		position: relative;
		margin: 0 0.5rem 0 0 !important;
		border-radius: 0 !important;
	}

	.swiper-pagination-bullet-active {
		opacity: 1 !important;
		background: var(--ion-app-primary) !important;
	}
}

.product-slider-container {
	.swiper-pagination-bullet {
		opacity: 1 !important;
		background: var(--ion-color-primary-contrast) !important;
	}

	.swiper-pagination-bullet-active {
		opacity: 1 !important;
		background: var(--ion-black-primary) !important;
	}
}

.categoryContent {
	--overflow: hidden !important;
}

ion-checkbox {
	--background-checked: var(--ion-color-primary-white);
	--border-color: var(--ion-color-primary-border);
	--border-color-checked: var(--ion-color-primary-border);
	--checkmark-color: var(--ion-color-primary-contrast);
	--size: 1.25rem;
	--border-width: 1px;
	--border-radius: 0;
}

//Dynamic about us css
.image-block {
	display: flex;
	align-items: center;
	margin: 3.125rem 0 3.125rem;

	.content-section {
		width: 50%;
	}

	.image-section {
		width: 50%;

		img {
			width: 90%;
		}
	}
}

.about-us-section {
	background: var(--ion-light-grey);
	padding: 4.375rem 4.375rem 2.5rem;
	margin: 1.25rem 0 3.125rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1.25rem;

	.block {
		display: flex;
		flex-direction: column;
		align-items: center;

		.circle-section {
			display: flex;
			width: 193px;
			height: 193px;
			background: #fff;
			border-radius: 50%;
			margin-bottom: 1.875rem;
			margin-bottom: 1.875rem;
			justify-content: center;

			.history-icon {
				position: relative;
				left: -5px;
			}
		}

		p {
			text-align: center;
		}
	}
}

.slider-section {
	font-size: 1rem;
	margin-bottom: 1.875rem;

	ion-slides {
		ion-slide {
			padding: 4.375rem 4.375rem 7.5rem;
			background: var(--ion-black-primary);

			.qutoe-section {
				width: 98px;
				text-align: left;
				margin-right: 92px;

				img {
					transform: rotate(180deg);
				}
			}

			.message-section {
				width: calc(100% - 11.875rem);
				color: #fff;

				.customer-message {
					font-size: 1.7rem;
					font-weight: 400;
					font-style: italic;
					text-align: left;
				}

				.customer-name {
					font-size: 1.12rem;
					text-align: left;
					font-weight: 600;
					margin-top: 1.25rem;
				}
			}
		}
	}
}


.filter-block {
	.filter-buttons {
		width: unset !important;
	}
}

.app-header {
	background: var(--ion-color-gray-20);
}

ion-icon.disabled,
.feather.disabled {
	cursor: not-allowed !important;
	opacity: 0.7 !important;
}

.select-popover {
	--offset-x: 50px;
	--offset-y: 22px;
}

.language-dropdown {
	--offset-x: 80px;
	--offset-y: 14px;
}

[dir="rtl"] .menu-section {
	.side-menu {
		border-left: 1px solid var(--ion-gray4);
		border-right: 0 !important;
		border-radius: 0 !important;
	}

	.menu {
		img {
			height: 9px;
			transform: rotate(180deg);
		}
	}
}

[dir="rtl"] .search-header {
	.right-block {
		.cart-icon {
			img {
				transform: scaleX(-1);
			}
		}
	}
}

.hide {
	display: none;
}

.fw-500 {
	font-weight: 500 !important;
}

.strike-css {
	text-decoration-line: line-through;
	color: var(--ion-divider);
	text-decoration-color: red;
	font-size: 0.8rem;

}