//--- dynamic about us css ends here
.two-fa-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding: 19px 5px 24px;

	.modal-close-mobile {
		position: absolute;
		right: 18px;
		top: 20px;
		cursor: pointer;
	}

	.header-line {
		position: absolute;
		background-color: var(--ion-divider);
		width: 36px;
		height: 4px;
		top: 5px;
	}

	.two-fa-header {
		font-weight: 600;
		font-size: 1.25em;
		line-height: 110%;
		color: var(--ion-black-primary);
		border-bottom: 1px solid #dbdce2;
		width: 100%;
		padding-bottom: 19px;
		margin-bottom: 15px;
		text-align: center;
	}

	.filter-by-options {
		width: 100%;

		ion-list {
			overflow: auto;
			max-height: 250px;

			.label-checked {
				font-weight: 600;
			}

			ion-checkbox {
				--border-width: 0px;
				--checkmark-color: var(--ion-color-primary-contrast);
				--background: transparent;
				--background-checked: transparent;
			}
		}
	}
}

.secure-support-block {
	font-size: 0.875rem;
	color: var(--ion-black-primary);
	display: flex;
	justify-content: center;
	margin-bottom: 78px;

	.section {
		display: flex;
		align-items: center;

		img {
			width: 1.875rem;
			position: relative;
			top: 0.3125rem;
		}

		.right-side {
			display: flex;
			flex-direction: column;
			margin-left: 1.125rem;

			.title {
				font-size: 1.28rem;
				font-weight: 700;
			}

			.text {
				color: var(--ion-grey1);
				padding-top: 0.25rem;
			}
		}
	}

	.vertical-line {
		height: auto;
		width: 1px;
		background: var(--grey-btn-bg);
		margin: 0 4.6875rem;
	}
}

[dir="rtl"] .secure-support-block {
	.section {
		.right-side {
			display: flex;
			flex-direction: column;
			margin-left: 0;
			margin-right: 1.125rem;
		}
	}
}

// custom drop down
.custom-dropdown {
	&::part(content) {
		width: 415px;
		border-radius: 0;
		max-height: 250px;
	}

	ion-list {
		padding: 0;
	}
}

// language drop down
.language-dropdown {

	ion-item {
		--padding-start: 0;
	}

	ion-label {
		font-size: 0.875rem !important;
		font-weight: 600;
		text-align: center;
	}

	ion-radio {
		position: absolute;
		width: 100%;
		height: 100%;
		padding-left: 15px;
		padding-right: 15px;
	}

	&::part(content) {
		width: 7.5rem;
		border-radius: 0;
	}

	.item-radio-checked {
		--background: var(--ion-app-primary);
		--background-focused: var(--ion-app-primary);
		--background-focused-opacity: 1;
		--background-hover: var(--ion-app-primary);
		--background-hover-opacity: 1;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}
}

.vertical-align-text-top {
	vertical-align: text-top;
}

.font-bold {
	font-weight: bold;
}

.cursor-default {
	cursor: default !important;
}

// not found page
#notfound {
	background-image: url("assets/images/not-found-bg.jpg");
}

// not found page
#notfound {
	background-image: url("assets/images/not-found-bg.jpg");
}

// button style
.custom-button {
	padding-top: 2.1875rem;

	ion-button {
		--background: var(--ion-app-primary);
		--border-radius: 0;
		--padding-start: 0;
		--padding-end: 0;
		width: 192px;
		height: 54px;
		font-weight: 600;
		font-size: 1rem;
		line-height: 1.125rem;
		letter-spacing: 0.4px;
		text-transform: uppercase;
		display: flex;
		align-items: center;

		.button-text {
			padding: 0 0.75rem 0 1.5625rem;
		}

		ion-icon {
			width: 1rem;
			height: 1rem;
		}
	}
}

.cta-button {
	padding-bottom: 0.9375rem !important;
	padding-top: 0;

	ion-button {
		margin-top: 0.75rem;
	}
}

.required-star {
	color: var(--ion-app-primary);
	padding-left: 0.3125rem;
}

.mobile-filters {
	display: none;
}

// register now
.register-block {
	background: url("assets/icon/register_bg.svg"), no-repeat;
	background-size: 100% 100%;
}

// Make menu relevant with header for solving width issue
app-header {
	position: relative;
	padding-bottom: 1.5625rem;
	background: var(--ion-color-gray-20);
}

.mobile-menu {
	display: none;
}

.account-menu-header {
	display: none;
}

.ionic5-star-rating {
	button {
		width: 1.0625rem !important;
		height: 0.9375rem !important;
		margin-right: 0.25rem !important;
	}
}

[dir="rtl"] {
	.ionic5-star-rating {
		button {
			transform: scalex(-1);
		}
	}
}

.star-container {
	height: 20px;
	margin-left: 0 !important;
}

.star-rating {
	font-size: 2em;
	color: #ffc107;
}

.add-review-rating {
	button {
		margin-right: 1.75rem !important;
	}
}

.star-rating {
	button {
		width: 1.375rem !important;
	}
}

#addReviewModal {
	--width: 60%;
	--height: auto;
	--max-height: 80vh;

	&::part(content) {
		overflow-y: auto;
	}
}

#notifyModal {
	--width: 600px;
	--height: auto;
	--max-height: 80vh;

	&::part(content) {
		overflow-y: auto;
	}
}

#termsWebModal {
	--width: 80%;
}

#orderDetailModal {
	--width: 830px;
	--height: 413px;
}

.header-md::after {
	background-image: none;
}

//google maps
.agm-map-container-inner {
	bottom: 1px !important;
}

.toast-custom-class {
	--max-width: 450px;
	--start: 67.5%;
}

.highlight {
	font-weight: bold !important;
}

.notify-me-modal {
	--height: unset !important;
}

@media screen and (max-width: 768px) {
	.cms-wrapper {
		padding-left: 0.9375rem !important;
		padding-right: 0.9375rem !important;
	}

	.mobile-view-listing {
		display: block;
	}

	.detail-mobile {
		padding: 0 0 0 !important;
	}

	.mobile-filters {
		display: block;
	}

	.mobile-menu,
	.searchbar-container {
		display: block;
	}

	.return-order-footer,
	.return-request-footer {
		display: flex;
	}

	.side-menu {
		display: none;
	}

	.account-menu-header {
		display: block;
	}

	.account-details-header {
		display: none;
	}

	.account-details-page {
		padding: 0 !important;

		.set-padding {
			padding: 0 !important;
		}

		.container-row {
			.side-menu-section {
				width: 100% !important;
				padding-right: 0 !important;
			}
		}

		.app-header,
		.app-footer,
		.main-content {
			display: none;
		}
	}

	.mobile-menu {
		.menu-list {
			padding: 1.5rem 0 0 0.625rem !important;
		}
	}

	.addresses {
		.address-listing-header {
			display: none;
		}
	}

	#addEditAddressModal {
		--height: 90%;
		--width: 85%;
	}

	#sampleModal {
		--height: 350px;
		align-items: flex-end;
	}

	#addEditPaymentCardModal {
		--height: 100%;
		height: 100% !important;
	}

	#selectVariantModal {
		--width: 100% !important;
		--height: auto;
		--max-height: 500px;
		align-items: flex-end;

		.ion-page {
			--height: auto;
			--max-height: 500px;
		}
	}

	.native-input.sc-ion-input-md {
		padding: 0 !important;
	}

	#twoFaModal {
		--height: 332px;
		--width: 100%;
		align-items: flex-end;
	}

	#forgotPassword {
		align-items: flex-end;
	}

	#loginModal {
		ion-content::part(scroll) {
			overflow-y: hidden !important;
		}
	}

	.common-input {
		ion-item {
			border-radius: 2px;
			--min-height: 48px;
			height: 52px;
			align-items: center;

			ion-input {
				bottom: 0.3125rem;
			}

			.company-registration {
				bottom: 0 !important;
				margin-top: 0 !important;
			}

			ion-label {
				position: relative;
				bottom: 0.375rem;
				color: var(--ion-grey1);
			}

			.input-color {
				bottom: 0 !important;
			}

			.countrycode-label {
				position: absolute;
				top: 0.75rem;
				bottom: 0.875rem;
				left: 2.5rem;
			}

			.countrycode-label-edit {
				position: absolute;
				top: 0.75rem;
				bottom: 0.875rem;
				left: 2.5rem;
			}
		}

		ion-item.item-has-focus {
			border: 1px solid var(--ion-black-primary);
			box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
		}
	}

	.common-input-textarea {
		ion-item {
			border-radius: 2px;
			--min-height: 48px;
			height: 150px;
			align-items: center;

			ion-input {
				bottom: 0.3125rem;
			}

			.company-registration {
				bottom: 0 !important;
				margin-top: 0 !important;
			}

			ion-label {
				position: relative;
				bottom: 0.375rem;
				color: var(--ion-grey1);
			}

			.input-color {
				bottom: 0 !important;
			}

			.countrycode-label {
				position: absolute;
				top: 0.75rem;
				bottom: 0.875rem;
				left: 2.5rem;
			}
		}

		ion-item.item-has-focus {
			border: 1px solid var(--ion-black-primary);
			box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.15);
		}
	}

	.toast-custom-class {
		--max-width: 100%;
		--start: 2%;
	}

	.pagination-wrapper {
		display: none !important;
	}

	.listing-header-btn {
		display: none !important;
	}

	.listing-header {
		display: none !important;
	}

	.return-order-header,
	.return-request-header {
		display: block;
	}
}

@media screen and (max-width: 992px) {

	// content side padding
	.content-side-padding {
		padding-left: 0.625rem;
		padding-right: 0.625rem;
	}

	.my-wishlist-page {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}

	.listing-table {
		display: none;
	}

	.mobile-view-listing,
	.searchbar-container {
		display: block;
	}

	.return-order-footer,
	.return-request-footer {
		display: flex;
	}

	.home-filter-div {
		display: none;
	}

	.home-page,
	.detail-page,
	.header-container {
		.home-container {
			padding-top: 0 !important;
		}
	}

	.search-header {
		padding: 0.8125rem 0.625rem !important;
		justify-content: center !important;

		.middle-block {
			width: 100% !important;
		}
	}

	.register-now {
		display: none;
	}

	.account-details-page {
		padding: 0.625rem !important;

		.app-header,
		.app-footer,
		.main-content {
			display: none;
		}
	}

	.side-menu {
		display: none;
	}

	.account-menu-header {
		display: block;
	}

	.mobile-menu {
		display: block;
	}

	.addresses {
		.address-listing-header {
			display: none;
		}
	}

	.pagination-wrapper {
		display: none !important;
	}

	.listing-header-btn {
		display: none !important;
	}

	.listing-header {
		display: none !important;
	}

	.return-order-header {
		display: block;
	}
}

@media screen and (max-height: 860px) {

	.register-Modal,
	.select-variant-modal {
		--height: 560px !important;
	}
}

@media screen and (max-height: 1130px) {

	.register-Modal,
	.select-variant-modal {
		--height: 850px;
	}

	#loginModal {
		--width: 560px;
	}
}

@media screen and (max-width: 1230px) {
	.my-wishlist-page {
		grid-template-columns: 1fr 1fr 1fr;
	}

	.dashboard-status-btn {
		min-width: unset !important;
	}

	.upper-part-watchlist {
		.select-btn-block {
			.select-block {
				ion-select {
					border: 1px solid var(--grey-btn-bg);
					padding: 0.5625rem 0.3125rem;
					width: 220px;
					margin-left: 0.625rem;
				}
			}

			.add-cart-button {
				ion-button {
					width: 206px;
				}
			}
		}
	}
}

@media screen and (max-width: 1368px) {

	.home-page,
	.detail-page,
	.header-container {
		// padding: 0.8125rem 1.875rem 0;

		.home-container {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	.bread-crumbs {
		padding: 1.125rem 5rem 0.8125rem !important;
	}

	.detail-page {
		padding: 0.8125rem 7.5rem 0 !important;
	}

	// container-row padding on small device
	.set-padding {
		padding-left: 0.9375rem !important;
		padding-right: 0.625rem !important;
	}

	.cart-Modal::part(content) {
		top: 4.6875rem !important;
		position: absolute !important;
		right: 1.875rem !important;
		left: unset !important;
		box-shadow: 0 1.25rem 7.5rem #00000026;
		z-index: 99;
	}

	[dir="rtl"] .cart-Modal::part(content) {
		direction: rtl;
		top: 4.6875rem !important;
		position: absolute !important;
		left: 1.875rem !important;
		right: unset !important;
		box-shadow: 0 1.25rem 7.5rem #00000026;
		z-index: 99;
	}
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
	#loginModal {
		padding-top: 5rem;
		align-items: start;
		--height: 515px;
	}

	#twoFaModal {
		padding-top: 5rem;
		align-items: start;
	}

	#addReviewModal,
	#notifyModal {
		padding-top: 5rem;
		align-items: start;
	}

	#orderDetailModal {
		padding-top: 5rem;
		align-items: start;
	}

	.register-Modal {
		--width: 560px;
	}

	.select-variant-modal {
		--width: 60%;
	}
}

@media screen and (min-width: 768px) and (max-height: 768px) {
	#loginModal {
		--height: 515px;
		--width: 562px;
	}

	.register-Modal {
		--width: 562px;
	}

	.select-variant-modal {
		--width: 60%;
	}
}

@media screen and (max-width: 1575px) {
	.detail-page {
		padding: 0 8.125rem;

		.detail-page-col {
			padding-left: 2.5rem !important;
		}
	}
}

@media screen and (min-width: 2200px) {

	.home-page .detail-page,
	.header-container {
		max-width: 2200px;
	}
}

.error-msg {
	font-size: 0.9875rem;
}


.required-text {
	color: var(--ion-app-primary);
}

//color CSS
.ion-focused.label-floating.sc-ion-label-md-h,
.ion-focused .label-floating.sc-ion-label-md-h,
.item-has-focus.label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h,
.item-has-placeholder.sc-ion-label-md-h:not(.item-input).label-floating,
.item-has-placeholder:not(.item-input) .label-floating.sc-ion-label-md-h,
.item-has-value.label-floating.sc-ion-label-md-h,
.item-has-value .label-floating.sc-ion-label-md-h,
.item-has-focus .label-floating.sc-ion-label-md-h:not(.ion-color) {
	color: var(--ion-grey1);
}

//ltr css
.edit-div {
	top: 0.9375rem;
	right: 0.9375rem;
}

.right-30 {
	right: 1.875rem;
}

.p-35-190 {
	padding: 2.1875rem 5.625rem;
}

.p-25-60 {
	padding: 1.5625rem 3.75rem;
}

.filter-checkbox-list {
	ion-checkbox::part(container) {
		padding: 0.0625rem !important;
		height: 1.5rem;
		width: 1.5rem;
	}
}

//rtl css

[dir="rtl"] .edit-div {
	position: absolute;
	top: 0.9375rem;
	left: 0.9375rem;
	right: unset;
}

[dir="rtl"] .right-30 {
	left: 1.875rem;
}

@media screen and (min-width: 768px) and (min-height: 768px) {
	.sample-modal {
		.ion-page {
			--height: 400px;
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.language-select {
	ion-select::part(text) {
		height: 33px;
		display: flex;
		align-items: center;
	}
}

.language-select-rtl {
	ion-select::part(icon) {
		right: 10px !important;
	}
}

.new-address-modal {

	.countrycode-label-edit {
		position: absolute;
		top: 31.5px;
	}

	.option-selected {
		height: 25px !important;
		max-height: 25px !important;
		min-height: 25px !important;
	}

	ion-label {
		color: var(--ion-grey1) !important;
		padding: 0;
		line-height: 1.4rem;
		max-height: 22px;
		margin-bottom: 0.4375rem !important;
	}

	.static-label {

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

	.static-label-mobile {
		text-transform: uppercase;
		position: absolute;
		top: 12.5px;
		margin-top: 0px !important;
		color: var(--ion-grey1) !important;

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

}

.new-address-modal-arabic {
	ion-select {
		padding-top: 0;
		padding-bottom: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	.countrycode-label-edit {
		position: absolute;
		top: 31.5px;
	}

	.option-selected {
		height: auto !important;
	}

	ion-label {
		color: var(--ion-grey1) !important;
		padding: 0;
		line-height: 1.4rem;
		max-height: 22px;
		margin-bottom: 0.4375rem !important;
	}

	.static-label {
		text-transform: uppercase;
		position: absolute;
		margin-top: 0px !important;
		color: var(--ion-grey1) !important;

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

	.static-label-mobile {
		text-transform: uppercase;
		position: absolute;
		top: 12.5px;
		margin-top: 0px !important;
		color: var(--ion-grey1) !important;

		@media screen and (max-width: 767px) {
			text-transform: capitalize;

		}
	}

}

#myaccount-modal {
	.ion-page {
		justify-content: unset;
	}
}


.sort-by-dropdown {
	ion-select::part(icon) {
		background-image: url("/assets/icon/downTriangle.svg") no-repeat center;
		opacity: 1 !important;
	}
}

.company-registration {
	ion-label {
		margin-bottom: 0;
	}
}

app-account-details {
	.bread-crumbs {
		background-color: var(--ion-color-white);
	}

	ion-content {
		--background: var(--ion-color-white);
	}
}

.paginator {
	.ngx-pagination {
		padding: 0;
		font: normal normal bold 0.875rem /21px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin: 0 20px;


		li {
			color: var(--ion-color-black-2);
			font-weight: 400;
		}

		li:not(.current, .pagination-next, .pagination-previous) {
			background: transparent;
		}

		.current {
			background: var(--ion-color-green-80);
			color: var(--ion-color-primary-contrast);
			border-radius: 2px;
			max-width: 50px;
			max-height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			margin: 0 10px;
		}

		a {
			background: var(--ion-color-primary-contrast);
			border-radius: 2px;
			max-width: 50px;
			max-height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			opacity: 1;
			margin: 0 10px;
		}

		a:hover,
		.ngx-pagination button:hover {
			background: var(--ion-color-primary-contrast);
		}

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url("/assets/icon/prev_arrow.svg");
			margin-right: 8px;
			margin-left: 0;
			position: relative;
			top: 2px;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url("/assets/icon/next_arrow.svg");
			margin-left: 8px;
			margin-right: 0;
			position: relative;
			top: 2px;
		}

		.pagination-previous a,
		.pagination-previous.disabled {
			border-radius: 2px;
			max-width: 50px;
			max-height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.pagination-next a,
		.pagination-next.disabled {
			background: transparent;
			border-radius: 2px;
			max-width: 50px;
			max-height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.pagination-next.disabled,
		.pagination-previous.disabled {
			opacity: 0.3;
			border-radius: 2px;
			max-width: 50px;
			max-height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

	}
}

.sort-by-item {
	ion-select::part(icon) {
		display: none;
	}

	ion-icon {
		width: 1rem;
		height: 1rem;
	}
}

.cms-wrapper {
	color: var(--ion-black-primary);
	font-size: 1rem;
	font-weight: 700;
	margin: 0 0 1.375rem;
	padding: 0 4.4375rem;
	margin-bottom: 2.5rem;

	.swiper-container-horizontal>.swiper-pagination-bullets {
		bottom: 3.1875rem;
		left: 0;
		width: 100%;
		text-align: left;
		margin-left: 16.25rem;
	}

	.swiper-pagination-bullet {
		width: 1.125rem;
		height: 1.125rem;
		background: var(--ion-black-primary) !important;
		border: 1px solid #fff;
	}

	.swiper-pagination-bullet-active {
		opacity: 1;
		background: #007aff !important;
		border: 1px solid #007aff;
	}

	.video-section {
		margin-bottom: 1.875rem;

		.thumbnail-part {
			background: url("assets/images/slider.jpg");
			width: 100%;
			height: 614px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-size: cover;

			.circle-block {
				width: 113px;
				padding: 1.125rem;
				height: 113px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				background: #fff;

				.play-icon {
					width: 100%;
					cursor: pointer;
				}
			}
		}

		video {
			width: 100%;
			height: 614px;
			background: #888;
		}
	}

	.page-heading {
		font-size: 2.6rem;
		margin-bottom: 1.5625rem;
	}

	hr {
		background: var(--grey-btn-bg);
		margin-bottom: 1.25rem;
	}

	.heading,
	.main-heading {
		margin: 0 0 0.8125rem;
		font-weight: 700;
	}

	.main-heading {
		font-size: 1.75rem;
	}

	.heading {
		font-size: 1.12rem;
	}

	p,
	ul {
		margin: 0 0 1.875rem;
		color: var(--ion-grey1);
		font-size: 1rem;
		font-weight: 400;
	}

	.note {
		font-size: 1rem;
		color: var(--ion-aqua);
		font-weight: 400;
	}

	ion-accordion-group {
		margin-bottom: 3.0625rem;

		ion-accordion {
			background: var(--ion-color-cool-gray-40);

			&::part(header) {
				background-color: var(--ion-color-cool-gray-40);
			}

			&::part(content) {
				background-color: var(--ion-color-cool-gray-40);
			}

			ion-item {
				border-bottom: 1px solid var(--ion-divider);
				background: var(--ion-color-cool-grey-40);
				--background: var(--ion-color-cool-gray-40);

				&::part(native) {
					background-color: var(--ion-color-cool-gray-40);
					background: var(--ion-color-cool-gray-40) !important;
					--background: var(--ion-color-cool-gray-40) !important;
				}
			}

			ion-label {
				font-size: 1.12rem;
				font-weight: 700;
			}

			ion-item::after {
				position: absolute;
				right: 0.75rem;
				top: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				z-index: 999;
				content: "+";
				color: var(--ion-app-primary);
				font-size: 1.75rem;
				font-weight: 600;
				cursor: pointer;
			}
		}

		.accordion-expanded {
			border: 1px solid var(--ion-divider);
			margin-bottom: 0.625rem;

			ion-item {
				&::part(native) {
					background-color: var(--ion-color-cool-gray-40);
				}

			}

			ion-item::after {
				position: absolute;
				right: 0.75rem;
				top: 1.5rem;
				z-index: 999;
				color: #0081CA;
				width: 0.8125rem;
				height: 3px;
				background: #0081CA;
				content: "";
				cursor: pointer;
			}
		}
	}
}

[dir="rtl"] {
	ion-accordion-group {
		ion-accordion {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}
		}

		.accordion-expanded {
			ion-item::after {
				right: unset !important;
				left: 0.75rem !important;
			}
		}
	}

	.buttons-container {
		.connect-buttons {

			.arrowIcon {
				position: absolute;
				right: unset !important;
				left: 0.8125rem !important;
			}
		}
	}
}

[dir="rtl"] {
	.detail-page .detail-page-container .detail-page-description .add-cart-container .add-cart-button ion-button .cart-icon {
		position: absolute;
		left: unset !important;
		right: 0 !important;
	}

}

.paginator-icon-rotate {
	.ngx-pagination {

		.pagination-previous a:before,
		.pagination-previous.disabled:before {
			content: url("/assets/icon/prev_arrow.svg");
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}

		.pagination-next a:after,
		.pagination-next.disabled:after {
			content: url("/assets/icon/next_arrow.svg");
			transform: rotate(180deg);
			top: -2px;
			position: relative;
		}
	}
}

// below the tab size
@media screen and (max-width: 550px) {

	ion-header ion-toolbar:first-of-type {
		padding-top: 0px;
	}

	ion-popover {
		--offset-y: 1.0625rem;
		--width: 100%;
		top: -45px;
	}
}

// below the tab size
@media screen and (max-width: 767px) {
	.cms-wrapper {
		.swiper-container-horizontal>.swiper-pagination-bullets {
			text-align: center;
			margin-left: unset;
		}
	}

	.home-page,
	.detail-page,
	.header-container {
		padding: 0 !important;
	}

	.top-slider {
		padding-bottom: 1rem !important;
	}

	.new-address-modal {
		ion-select {
			padding-bottom: 0.625rem;
		}

		ion-label {
			margin-bottom: 0 !important;
		}
	}

	.new-address-modal-arabic {
		ion-select {
			padding-bottom: 0.625rem;
		}

		ion-label {
			margin-bottom: 0 !important;
		}
	}

	.desktop-visibility {
		display: none;
	}

	.mobile-visibility {
		display: block;
	}

	.static-label {
		margin-bottom: 0.025rem;
	}

	.desktop-visibility-in-flex {
		display: none;
	}

	.mobile-visibility-in-flex {
		display: flex;
	}

	.search-header {
		.left-block {
			display: none !important;
		}

		.right-block {
			display: none !important;
		}

		.mobile-user-block-ltr {
			display: block;
			padding: 0.3125rem 25rem 0 0 0.625rem;
			cursor: pointer;
		}

		.mobile-user-block-rtl {
			display: block;
			padding-right: 1rem;
			cursor: pointer;
		}
	}

	.main-header {
		display: none !important;
	}

	.app-footer {
		display: none;
	}

	.registration-pass {
		width: 100% !important;
	}
}

.sortby-dropdown {
	&::part(content) {
		width: 13rem;
	}
}

.footer-md::before {
	background-image: none;
}

.registration-pass {
	width: 75%;
}



.clear-button {
	background-color: var(--ion-grey5);
}

.container {
	width: 100%;
	padding-right: 0;
	padding-left: 0;
	margin-right: auto !important;
	margin-left: auto !important;
	overflow-x: hidden;
	max-width: 100%;

	//commenting beacuse of home ui changes here
	// @media (min-width: 1800px) {
	//     max-width: 1600px;
	// }
}

.more-info-tooltip {
	position: sticky;
	right: -27px;
	top: 25%;

	img {
		cursor: pointer;
	}
}

.more-info-tooltip .more-info-tooltiptext {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: 1.75rem;
	right: 0%;
	font-size: 0.875rem;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
}

.more-info-tooltip .more-info-tooltiptext-left {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: -6px;
	right: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
	left: 25px;
}

.more-info-tooltip .more-info-tooltiptext-cart {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
	left: -70px;
}

[dir="rtl"] .more-info-tooltip .more-info-tooltiptext-cart {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
	right: -70px;
}

[dir="rtl"] .more-info-tooltip .more-info-tooltiptext-left {
	visibility: hidden;
	background-color: black;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 0.3125rem 0.5rem;
	top: -6px;
	left: 0;
	font-size: 0.875rem;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	z-index: 99999999999999999;
	white-space: nowrap;
	right: 25px;
}

.more-info-file-tooltip {
	width: fit-content !important;
}

.more-info-tooltip:hover .more-info-tooltiptext,
.more-info-tooltip:hover .more-info-tooltiptext-left,
.more-info-tooltip:hover .more-info-tooltiptext-cart {
	visibility: visible;
}

.error-txt {
	color: var(--payment-fail);
	font-size: 0.8rem;
}

#wishlistModal {
	--height: 350px;
	align-items: flex-end;

}

ion-toolbar {
	ion-title {
		width: auto;
		display: inherit;
	}
}

// .select-popover,
// .popover-desktop {
//     width: 70%;
//     --width: 70%;
//     min-width: 70%;
//     --min-width: 70%;
// }

.rfq-dropdown {
	top: -10px;

	.popover-wrapper {
		--width: 100%;
	}

	ion-item {
		--padding-start: 16px;
		--padding-end: 16px;
	}

	ion-label {
		font-size: 1rem !important;
		font-weight: 600;
		text-align: left;
	}

	ion-radio {
		position: absolute;
		width: 100%;
		height: 100%;
	}



	&::part(content) {
		--width: 65%;
		// --max-width: unset !important;
		border-radius: 0;
		margin-top: 0.625rem;
	}

	.item-radio-checked {
		--background: var(--ion-app-primary);
		--background-focused: var(--ion-app-primary);
		--background-focused-opacity: 0.2;
		--background-hover: var(--ion-app-primary);
		--background-hover-opacity: 0.12;
		--color: #fff;
	}

	ion-list {
		padding: 0;
	}
}

.w-50 {
	width: 50%;
}

.page-container {

	padding: 0.1rem 4.4375rem;

	@media screen and (max-width: 992px) {
		padding: 0.8125rem 0.625rem;
	}
}

.qty-div {
	display: flex;
	height: 100%;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--ion-divider) !important;
}

.ant-rate-star-zero {

	.ant-rate-star-first,
	.ant-rate-star-second {
		color: grey !important;
	}

}

.header-md,
.footer-md {
	box-shadow: none;
}




.custom-date {
	border-bottom: 1px solid var(--ion-divider);
	background: #fff;
	--background: #fff;

	&::part(native) {
		background-color: #fff;
		background: #fff;
		--background: #fff;
	}
}

.action-icon {
	height: 25px;
	width: 25px;
}

.ion-valid.sc-ion-input-md-h {
	// --highlight-height: 0px !important;
}

.sc-ion-textarea-md-h {
	--border-width: 0;
	--border-color: transparent;
	--padding-top: 0px;
	--padding-end: 0px;
	--padding-bottom: 8px;
	--padding-start: 0px;
	--highlight-height: 0px;
}

ion-toolbar.sc-ion-searchbar-md-h,
ion-toolbar .sc-ion-searchbar-md-h {
	-webkit-padding-start: 0px;
	padding-inline-start: 0px;
	-webkit-padding-end: 0px;
	padding-inline-end: 0px;
	padding-top: 3px;
	padding-bottom: 3px;
}

.text-uppercase {
	text-transform: uppercase;
}

.label-floating.textarea-fill-outline.sc-ion-textarea-md-h .label-text-wrapper.sc-ion-textarea-md {
	background: white;
	padding: 0 5px;
}

.max-col-200 {
	max-width: 200px;
	width: 200px;
	min-width: 200px;
}