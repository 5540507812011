/* English/Heading 1/Regular/As typed/None */
.heading-1-regular {
	font-size: 49px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Heading 1/Regular/As typed/Underline */
.heading-1-regular-underline {
	font-size: 49px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 1/Regular/As typed/Strikethrough */
.heading-1-regular-strikethrough {
	font-size: 49px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 1/Bold/As typed/None */
.heading-1-bold {
	font-size: 49px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Heading 1/Bold/As typed/Underline */
.heading-1-bold-underline {
	font-size: 49px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 1/Bold/As typed/Strikethrough */
.heading-1-bold-strikethrough {
	font-size: 49px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 2/Regular/As typed/None */
.heading-2-regular {
	font-size: 39px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Heading 2/Regular/As typed/Underline */
.heading-2-regular-underline {
	font-size: 39px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 2/Regular/As typed/Strikethrough */
.heading-2-regular-strikethrough {
	font-size: 39px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 2/Bold/As typed/None */
.heading-2-bold {
	font-size: 39px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Heading 2/Bold/As typed/Underline */
.heading-2-bold-underline {
	font-size: 39px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 2/Bold/As typed/Strikethrough */
.heading-2-bold-strikethrough {
	font-size: 39px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 3/Regular/As typed/None */
.heading-3-regular {
	font-size: 31px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Heading 3/Regular/As typed/Underline */
.heading-3-regular-underline {
	font-size: 31px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 3/Regular/As typed/Strikethrough */
.heading-3-regular-strikethrough {
	font-size: 31px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 3/Bold/As typed/None */
.heading-3-bold {
	font-size: 31px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Heading 3/Bold/As typed/Underline */
.heading-3-bold-underline {
	font-size: 31px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 3/Bold/As typed/Strikethrough */
.heading-3-bold-strikethrough {
	font-size: 31px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 4/Regular/As typed/None */
.heading-4-regular {
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Heading 4/Regular/As typed/Underline */
.heading-4-regular-underline {
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 4/Regular/As typed/Strikethrough */
.heading-4-regular-strikethrough {
	font-size: 25px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Heading 4/Bold/As typed/None */
.heading-4-bold {
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Heading 4/Bold/As typed/Underline */
.heading-4-bold-underline {
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Heading 4/Bold/As typed/Strikethrough */
.heading-4-bold-strikethrough {
	font-size: 25px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Large/Regular/As typed/None */
.large-regular {
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Large/Regular/As typed/Underline */
.large-regular-underline {
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Large/Regular/As typed/Strikethrough */
.large-regular-strikethrough {
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Large/Bold/As typed/None */
.large-bold {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Large/Bold/As typed/Underline */
.large-bold-underline {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Large/Bold/As typed/Strikethrough */
.large-bold-strikethrough {
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Base/Regular/As typed/None */
.base-regular {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Base/Medium/As typed/None */
.base-medium {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
}

/* English/Base/Regular/As typed/Underline */
.base-regular-underline {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Base/Regular/As typed/Strikethrough */
.base-regular-strikethrough {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Base/Bold/As typed/None */
.base-bold {
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Base/Bold/As typed/Underline */
.base-bold-underline {
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Base/Bold/As typed/Strikethrough */
.base-bold-strikethrough {
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Small/Regular/As typed/None */
.small-regular {
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/Small/Regular/As typed/Underline */
.small-regular-underline {
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Small/Regular/As typed/Strikethrough */
.small-regular-strikethrough {
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/Small/Bold/As typed/None */
.small-bold {
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/Small/Bold/As typed/Underline */
.small-bold-underline {
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/Small/Bold/As typed/Strikethrough */
.small-bold-strikethrough {
	font-size: 13px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}











/* English/XSmall/Regular/As typed/None */
.xsmall-regular {
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

/* English/XSmall/Regular/As typed/Underline */
.xsmall-regular-underline {
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/XSmall/Regular/As typed/Strikethrough */
.xsmall-regular-strikethrough {
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	text-decoration-line: line-through;
}

/* English/XSmall/Bold/As typed/None */
.xsmall-bold {
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
}

/* English/XSmall/Bold/As typed/Underline */
.xsmall-bold-underline {
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: underline;
}

/* English/XSmall/Bold/As typed/Strikethrough */
.xsmall-bold-strikethrough {
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
	text-decoration-line: line-through;
}