.color-app-grey {
	color: var(--ion-grey1);
}

.color-app-grey2 {
	color: var(--ion-grey2);
}

.color-app-black {
	color: var(--ion-black-primary);
}

.color-success {
	color: var(--payment-success);
}

.color-white {
	color: white;
}

.color-black {
	color: black;
}

.color-primary {
	color: var(--ion-app-primary);
}

.color-app-green {
	color: var(--ion-color-green-80);
}

.color-app-orange {
	color: var(--ion-orange-primary);
}

.color-danger {
	color: var(--ion-color-danger);
}

.color-blue-60 {
	color: var(--ion-color-blue-60)
}

.bg-primary {
	background: var(--ion-app-primary);
}


.bg-dark-grey {
	background-color: var(--ion-color-gray-50);
}

.bg-light-green {
	background: var(--ion-color-green-100);
}

.bg-light-orange {
	background: var(--ion-color-red-100);
}

.bg-new-grey {
	background: var(--new-border-grey);
}

.bg-grey {
	background: var(--ion-color-gray-20) !important;
}

.bg-gray-90 {
	background: var(--ion-color-gray-90) !important;
	--background: var(--ion-color-gray-90) !important;
}

.bg-white {
	background: white;
}

.btn-app-green {
	background: rgba(22, 190, 156, 0.2) !important;
	color: rgb(22, 190, 156) !important;
}

.btn-app-red {
	background: rgba(244, 67, 54, 0.2) !important;
	color: rgb(244, 67, 54) !important;
}

.btn-app-blue {
	background: rgba(0, 129, 202, 0.2) !important;
	color: rgb(0, 129, 202) !important;
}

.bg-transparent {
	background: transparent !important;
}