// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--ion-font-family: 'Roboto', sans-serif !important;
	--ion-default-font: "Roboto", sans-serif !important;
	--ion-text-lower: lowercase;
	--ion-text-upper: uppercase;
	/** primary **/
	--ion-color-primary: #0081CA;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #0081CA;
	--ion-color-primary-tint: #0081CA;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #5260ff;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #6370ff;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	/** danger **/
	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235, 68, 90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	/** medium **/
	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-app-primary: #0081CA;
	--ion-app-light-primary: #e2f6fd;
	--ion-orange-primary: #fb772b;
	--ion-black-primary: #000E16;
	--ion-grey1: #456371;
	--ion-grey2: #758d96;
	--ion-gray3: #103e53;
	--ion-grey4: #F1F1F1;
	--ion-grey5: #757575;
	--ion-font-grey: #333;
	--ion-gray4: #9aabb2;
	--ion-aqua: #37c2d4;
	--ion-divider: #b3b3b3;
	--ion-no-data-bg: #eff3f4;
	--ion-light-grey: #f0f3f4;
	--ion-app-yellow: #facd2e;
	--ion-app-green: #67b00a;
	--ion-app-light-green: #a1d85b;
	--ion-app-orange: #ff7917;
	--ion-app-light-orange: #ff975c;
	--ion-app-pink: #ff7b73;
	--ion-app-blue: #139dd3;
	--ion-dark-grey: #565656;
	--ion-btn-reject: #ff79172b;
	--ion-color-primary-border: #d5dadc;
	--color-selected: #0081CA;
	--dark-blue-bg-btn: #0081CA;
	--white-grey: #fafbfb;
	--black-bg-btn: #f0f3f4;
	--blue-btn-bg: #6dcff633;
	--green-btn-bg: #acdb6140;
	--red-btn-bg: #e94b4b1a;
	--grey-btn-bg: #d5dadc;
	--orange-btn-bg: #ffebcd;
	--orange-btn-text: #ff8c00;
	--payment-color: #2d2d2f;
	--payment-success: #16BE35;
	--payment-fail: #ff0000;
	--payment-warning: #facd2e;
	--blue-text: #0a3040;
	--ion-cyan-color: #1e98a8;
	--ion-dark-cyan-color: #2A7C87;
	--ion-dark-cyan2: #388D98;
	--btn-red-bg: #f53648;

	//initial colors for Jazeera Paints according to Figma
	--ion-color-gray-10: #FFFFFF;
	--ion-color-gray-20: #F4F4F4;
	--ion-color-gray-30: #E0E0E0;
	--ion-color-gray-40: #C6C6C6;
	--ion-color-gray-50: #A8A8A8;
	--ion-color-gray-60: #8D8D8D;
	--ion-color-gray-70: #6F6F6F;
	--ion-color-gray-80: #525252;
	--ion-color-gray-81: #434345;
	--ion-color-gray-90: #F6F6F6;
	--ion-color-gray-100: #000E16;
	--ion-color-cool-gray-20: #F8FAFF;
	--ion-color-cool-gray-30: #E9ECF3;
	--ion-color-cool-gray-40: #DAE0EE;
	--ion-color-cool-gray-50: #C6CEDA;
	--ion-color-blue-100: #0081CA;
	--ion-color-green-60: #DCF5F0;
	--ion-color-green-80: #16BE9C;
	--ion-color-green-80-rgb: 22, 190, 156;
	--ion-color-green-100: #12805C;
	--ion-color-blue-40: #183E75;
	--ion-color-blue-40-rgb: 24, 62, 117;
	--ion-color-blue-60: #008ECF;
	--ion-color-blue-60-rgb: 0, 142, 207;
	--ion-color-red-40: #E30613;
	--ion-color-red-40-rgb: 227, 6, 19;
	--ion-color-red-60: #F5DCDC;
	--ion-color-red-100: #B3261E;
	--ion-color-matt: #AEB3C1;
	--ion-color-text-field: #5F6368;
	--ion-color-text-field-border: #C6CEDA;
	--ion-color-linear-gradient: linear-gradient(45deg, #FEFEFF 15%, #C6CEDA 15%);
	--ion-color-semi-gloss: radial-gradient(circle, #E4E9F5 100%, #AEB4C4 100%);
	--ion-color-gloss: radial-gradient(circle, #F7F7F9 100%, #B7BBC8 100%);
	--ion-color-border: linear-gradient(45deg, #FEFEFF 100%, #C6CEDA 100%);
	--new-border-grey: #E5E7E8;
	--new-text-color: #456371;
	--ion-color-white: #ffffff;
	--ion-color-black-2: #434345;
	--ion-color-black-3: #000810;
	--ion-color-confirm: #81FF89;
	--ion-color-confirm-2: #003A04;
	--ion-color-pending: #FFE29A;
	--ion-color-pending-2: #644700;
	--ion-color-delivered: #C5D1FF;
	--ion-color-delivered-2: #00104E;

}