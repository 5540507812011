.back-icon {
	z-index: 1000;
}

[dir="rtl"] .back-icon {
	transform: rotate(180deg);
}

.pointer {
	cursor: pointer !important;
}

.width-100 {
	width: 100%
}

.mw-70 {
	min-width: 4.375rem;
}

.mw-90 {
	min-width: 5.625rem;
}

.mw-100 {
	min-width: 100px;
}

.mw-120 {
	min-width: 7.5rem;
}

.mw-140 {
	min-width: 140px;
}

.mw-150 {
	min-width: 150px;
}

.mw-160 {
	min-width: 160px;
}

.mw-170 {
	min-width: 170px;
}

.mw-180 {
	min-width: 180px;
}

.mw-200 {
	min-width: 200px;
}

.mw-250 {
	min-width: 250px;
}

.mw-310 {
	min-width: 310px;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.in-english {
	display: block !important;
}

.in-arabic {
	display: none !important;
}


.btn-green {
	background: var(--green-btn-bg);
	color: var(--ion-app-green);
}

.btn-black {
	background: var(--black-bg-btn);
	color: var(--ion-black-primary);
}

.btn-red {
	background: var(--red-btn-bg);
	color: var(--ion-orange-primary);
}

.btn-blue {
	background: var(--blue-btn-bg);
	color: var(--ion-app-blue);
}

.btn-grey {
	background: var(--grey-btn-bg);
	color: var(--ion-black-primary);
}

.btn-dark-blue {
	background: var(--dark-blue-bg-btn);
	color: var(--ion-color-primary-contrast);
}

.btn-orange {
	background: var(--orange-btn-bg);
	color: var(--orange-btn-text);
}

.rotate-180 {
	transform: rotate(180deg) !important;
}


[dir="rtl"] {
	.info-dropdown {
		margin-left: 70px;
		margin-right: unset !important
	}
}

.wpwl-form-card {
	border-radius: 10px;
	background-image: url('./assets/images/BG blue.svg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right;
	padding: 20px 20px 40px 20px;
	border-radius: 17px;

	@media screen and (max-width: 768px) {
		background-position: 95% center;
		padding: 10px 20px 20px 20px;
	}
}

.wpwl-form-card {
	@media screen and (max-width: 768px) {
		margin: 0 1rem;
	}
}

.wpwl-button-pay:hover,
.wpwl-button-pay:focus,
.wpwl-button-pay:active,
.wpwl-button-pay {
	background-color: var(--ion-app-primary);
	border-color: var(--ion-app-primary);
}

.wpwl-button-pay[disabled],
.wpwl-button-pay[disabled]:hover,
.wpwl-button-pay[disabled]:focus,
.wpwl-button-pay[disabled]:active {
	background-color: var(--ion-app-primary);
	border-color: var(--ion-app-primary);
}

.wpwl-control,
.wpwl-control-cardNumber,
.wpwl-control-cvv {
	background-color: transparent !important;
	background: transparent !important;
	color: rgb(179, 179, 179) !important;
	border-top-width: 0;
	border-left-width: 0;
	border-right-width: 0;
	border-radius: 0;
	border-color: rgba(255, 255, 255, 0.5);
}

.wpwl-group,
.wpwl-label-brand,
.wpwl-wrapper-brand,
.wpwl-wrapper-registration {
	color: white !important;
	background: transparent !important;

}

.wpwl-control-brand>option,
.wpwl-control-brand>option:hover {
	color: black;
}

.wpwl-clearfix:before,
.wpwl-clearfix:after {
	background: transparent !important;
}

.wpwl-wrapper,
.wpwl-wrapper-cardNumber,
.wpwl-wrapper-cvv,
.wpwl-wrapper-expiry,
.wpwl-control-expiry,
.wpwl-has-error {
	background: transparent !important;
}

.wpwl-button-pay {
	float: left;
	margin: 0;
	border-radius: 1px;
}

.wpwl-group {
	margin: 20px 0 0 0;
}

.wpwl-label {
	margin-bottom: 5px;
}

.textCapitilize {
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}

.min-height-content {
	min-height: calc(100vh - 457px);

	@media screen and (max-width: 768px) {
		min-height: unset;
	}
}

.align-end {
	display: flex;
	justify-content: flex-end;
}

.align-center {
	align-items: center;
}

.app-text-capitize {
	text-transform: lowercase;

	&::first-letter {
		text-transform: uppercase;
	}
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
	display: contents;
}


.header-background {
	backdrop-filter: none;
}

.cursor-disable {
	cursor: not-allowed !important;
}

sup {
	top: -0.2em;
}

.wrap-slider {
	.swiper-container {
		.swiper-wrapper {
			flex-wrap: wrap !important;
		}
	}
}

.swiper-scrollbar {
	border-radius: none !important;
	background: transparent !important;
}

.swiper-scrollbar-drag {
	border-radius: var(--swiper-scrollbar-border-radius, none) !important;
	background: var(--ion-color-primary) !important;
}

.swiper-horizontal>.swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
	top: 1px !important;
	height: 10px !important;
	width: 100% !important;
	left: 0 !important;
}

ion-header {
	box-shadow: none !important;
}


.primary-btn {
	ion-button {
		--padding-start: 1rem;
		--padding-end: 1rem;
		--background-hover: none;

		&.button-disabled {
			color: var(--ion-color-gray-60) !important;
			--background: var(--ion-color-gray-20) !important;
			--border-color: var(--ion-color-gray-50) !important;
			opacity: 1 !important;

			.text-css {
				color: var(--ion-color-gray-60) !important;
			}
		}

		&:hover {
			--box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30) !important;
			--border-radius: 5px !important;
			border-radius: 5px;
		}
	}
}

.secondary-btn {
	ion-button {
		--padding-start: 1rem;
		--padding-end: 1rem;
		--background-hover: var(--ion-color-gray-20);
		--background-hover-opacity: 1;

		&.button-disabled {
			color: var(--ion-color-gray-50) !important;
			--background: var(--ion-color-primary-contrast) !important;
			--border-color: var(--ion-color-gray-50) !important;
			opacity: 1 !important;

			.text-css {
				color: var(--ion-color-gray-60) !important;
			}
		}

		&:hover {
			--border-color: var(--ion-color-gray-50) !important;
			border-radius: 5px;
		}
	}
}

.tertiary-btn {
	ion-button {
		--padding-start: 1rem;
		--padding-end: 1rem;
		--background-hover: var(--ion-color-gray-40);
		--background-hover-opacity: 1;

		&.button-disabled {
			color: white !important;
			--color: white !important;
			--background: var(--ion-color-primary) !important;
			opacity: 0.5 !important;
			cursor: not-allowed !important;

			.text-css {
				color: white !important;
				cursor: not-allowed !important;
			}
		}

		&:hover {
			--border-color: var(--ion-color-gray-40) !important;
			--background: var(--ion-color-gray-40) !important;
			background: var(--ion-color-gray-40) !important;
			border-radius: 5px;
		}
	}
}

ion-footer {
	background: white !important;
}

ion-button {
	--border-radius: 5px !important;
}

button {
	border-radius: 5px !important;
	text-transform: capitalize !important;
}

.sc-ion-input-md-s>[slot=start]:last-of-type {
	margin-inline-end: 0.5rem;
}

.d-flex {
	display: flex;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	margin: 0 !important;
}

input[type="number"] {
	-moz-appearance: textfield !important;
}

ion-modal {
	.ion-page {
		background-color: white;
	}
}

app-cart-header {
	padding: 15px 0;
}